import React from 'react'

class Hero extends React.Component {

    render() {
        const pageTitle = this.props.data
        const category = this.props.category

        return (
            <div>
                <div className="page-heading">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="page-heading__category">{category}</div>
                                <h1 className="page-heading__title">{pageTitle}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Hero
